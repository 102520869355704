import React, { useMemo } from 'react'
import Footer from '../../components/footer'
import '../../styles/downloads.less'
import SEO from '../../components/SEO'
import { navigate } from 'gatsby'
import { parse } from 'query-string'
import Header from '../../components/header'
const loadText = require('src/utils').loadText
const data = loadText('downloads')
// import { isValid } from './index'
// import {ModalFormWrap} from ''
import { DownloadFormButton, DownloadFormAuto } from '../../components/form-biz'
const colors = [
  {
    color: 'linear-gradient(180deg, #ECFAF9 0%, #DEF6F5 100%)',
    hover: '#26D98E',
  },
  {
    color: 'linear-gradient(180deg, #FAF4FF 0%, #F4E6FF 100%)',
    hover: '#CF96FF',
  },
  { color: '#E0F9FF', hover: '#00D2FC' },
  { color: '#DFFAFC', hover: '#19ADFD' },

  {
    color: 'linear-gradient(180deg, #FFFDF2 0%, #FFF9E1 100%)',
    hover: '#FFD322',
  },
]
const B1 = () => (
  <div className="downloads-b1 full-bg">
    <div className="downloads-b1-title">{data.content.title}</div>
    <div className="downloads-b1-content">
      {data.content.links.map(({ name, link }, idx) => {
        const i = idx % colors.length
        return (
          <div className="downloads-b1-item">
            <div style={{ background: colors[i].color }} className="normal">
              <div
                className="downloads-b1-item-flex"
                style={{
                  backgroundImage: `url("/downloads/${i + 1}-bg.svg")`,
                  backgroundPosition: 'bottom',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div>
                  <div className="font-28 semi-bold t-color name">{name}</div>
                  <DownloadFormButton
                    defaultData={{ filename: name }}
                    source="downloads"
                    buttonClassName="downloads-btn"
                    onSuccess={() => {
                      const link = document.createElement('a')
                      const file = `${name}.pdf`
                      link.href = `/downloads/${name}.pdf`
                      link.download = file
                      link.dispatchEvent(new MouseEvent('click'))
                    }}
                  >
                    <>
                      <img src="/downloads/download.svg" alt="download" />
                      {data.btnText}
                    </>
                  </DownloadFormButton>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)
export default function Legal({ location }) {
  // const [isShow, setIsShow] = useState(false)
  // useEffect(() => {
  //   if (isValid()) {
  //     setIsShow(true)
  //   } else {
  //     navigate('/downloads/')
  //   }

  // }, [])
  const downloadFile: any = parse(location.search).downloadFile || ''
  const decodedName: any = decodeURIComponent(downloadFile)
  // console.log(downloadFile)
  const hiddenLinkMap = useMemo(() => {
    return new Set(data.hiddenLinks || [])
  }, [data.hiddenLinks])
  return (
    <div className="downloads">
      <SEO {...data.seo} />
      <div className="downloads-topBanner">
        <div className="downloads-topBanner-bg full-bg">
          <div className="headerContainer">
            <Header logo="/logo-blue.svg" />
          </div>
          <div className="downloads-topBanner-content">
            <h1 className="title">{data.topBanner.title}</h1>
          </div>
        </div>
      </div>
      <B1 />
      <DownloadFormAuto
        source="downloads"
        defaultOpen={hiddenLinkMap.has(decodedName)}
        onSuccess={() => {
          const link = document.createElement('a')
          const file = `${decodedName}.pdf`
          link.href = `/cn-downloads/${decodedName}.pdf`
          link.download = file
          link.dispatchEvent(new MouseEvent('click'))
        }}
      />
      <Footer />
    </div>
  )
}
